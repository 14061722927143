import axios from 'axios';
import cheerio from 'cheerio';
import { useState } from 'react';
import './App.css';
// import AudioPlayer from './AudioPlayer';
// import TestAudio from './TestAudio';

function App() {
  const [lyrics, setLyrics] = useState("")
  const [search, setSearch] = useState("")
  async function getLyrics() {
    const response = await axios.get("https://eska.vedani.ovh:8082/genius?search="+search)
    if (response.status === 200) {
      
      console.log(response.data)
      const responseSong = await axios.get(response.data.result)
      if (responseSong.status === 200) {
        console.log(responseSong.data)
        const $ = cheerio.load(responseSong.data);
        const title = $('div.Lyrics__Container-sc-1ynbvzw-6').html();
        setLyrics(title)
      }
      // var test = response.data.result.replaceAll(`style="position:absolute;opacity:0;width:0;height:0;pointer-events:none;z-index:-1"`, "")
      // setLyrics(test)
      // document.getElementsByTagName("lyrics").removeAttribute("href");
    }else {
      setLyrics("<p>Nie ma tekst</p>")
    }
  }
  function test() {
    console.log(document.getElementsById("lyrics"))
  }

  return (
    <div className="App">
      {/* <AudioPlayer/>
      <br/>
      <TestAudio/> */}
      <input onChange={(e) => setSearch(e.target.value)}></input>
      <button onClick={getLyrics}>Get api</button>
      <div id='lyrics' dangerouslySetInnerHTML={{ __html: lyrics }}></div>
      <button onClick={test}>get</button>
    </div>
  );
}

export default App;
